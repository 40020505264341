<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Pilih Jalur Penerimaan</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-row align="center">
          <v-col cols="12">
            <v-select
              v-model="filterJalur"
              :items="jalurItems"
              dense
              item-value="id"
              item-text="NAME"
              label="Pilih Jalur"
              v-on:change="jalurChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <br />
    <v-data-table
      :headers="headers"
      :items="student"
      :search="search"
      class="elevation-1"
      item-key="name"
    >
      <template v-slot:item.nomor="{ item }">
        {{ student.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.SEX="{ item }">
        <span v-if="item.SEX == 'M'"> L </span>
        <span v-if="item.SEX == 'F'"> P </span>
      </template>
      <template v-slot:item.REGNUMBER="{ item }">
        {{ item.REGNUMBER }}
      </template>
      <template v-slot:item.PILIHAN="{ item }">
        <!-- {{ item.school_selected }} -->
        <ol class="pa-1">
          <span v-for="dt in item.PILIHAN" :key="dt.id">
            <li>{{ dt.NAME }}</li>
          </span>
        </ol>
      </template>
      <template v-slot:item.VALIDATION="{ item }">
        <span v-if="item.VALIDATION == 1">
          <v-chip small rounded color="success" dark>
            Selesai Diverifikasi
          </v-chip>
        </span>
        <span v-else>
          <v-chip small rounded color="error" dark> Belum Diverifikasi </v-chip>
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Daftar Siswa</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="">
              <v-btn color="success" dark class="mb-2">
                <download-excel :data="student" :fields="json_fields">
                  <v-icon left>mdi-download</v-icon>Excel
                </download-excel>
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.NAME"
                        label="Dessert name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.calories"
                        label="Calories"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.fat"
                        label="Fat (g)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.carbs"
                        label="Carbs (g)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.protein"
                        label="Protein (g)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          x-small
          class="mr-1"
          rounded
          color="info"
          dark
          @click="detailSiswa(item)"
        >
          Detail
        </v-btn>
        <span v-if="item.DUPLICATE_STATUS == true">
          <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          <v-btn
            x-small
            class="mr-1"
            rounded
            color="error"
            dark
            @click="deleteItem(item)"
          >
            Hapus
          </v-btn>
        </span>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Siswa",
  data: () => ({
    baseUrl: process.env.VUE_APP_API_URL,
    url: "/api/getFile/",
    dialogLoading: false,
    dialog: false,
    dialogDelete: false,
    search: "",
    menus: [{ title: "Biodata" }, { title: "Pilihan Sekolah" }],
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama Lengkap",
        align: "left",
        value: "NAME",
      },
      { text: "NISN", value: "NISN" },
      { text: "L/P", value: "SEX" },
      { text: "Tanggal Lahir", value: "DOB" },
      { text: "Nomor Pendaftaran", value: "REGNUMBER" },
      { text: "Pilihan Sekolah", value: "PILIHAN" },
      { text: "Status", value: "VALIDATION" },
      { text: "Aksi", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    student: [],
    expanded: [],
    schools: [],
    jalurItems: [],
    filterJalur: null,
    name: "",
    nisn: "",
    singleExpand: true,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },

    json_fields: {
      id: "id",
      alamat: "ADDRESS",
    },
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getJalur();
    },

    getRegistered(item) {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/getRegistered/" + item, {}, config)
        .then((response) => {
          this.dialogLoading = false;
          // this.student = response.data;
          // console.log(this.student);
          var Data = response.data;
          // var newData = Data.filter((e) => e.ADMISSIONID == item);
          this.student = Data;
          console.log(Data);
        })
        .catch((error) => {
          this.dialogLoading = false;
          this.student = [];
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    getJalur() {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/admission", {}, config)
        .then((response) => {
          this.dialogLoading = false;
          this.jalurItems = response.data.data;
          console.log(this.jalurItems);

          if (this.user.role == 1) {
            this.headers = [
              { text: "No", value: "nomor" },
              {
                text: "Nama Lengkap",
                align: "left",
                value: "NAME",
              },
              { text: "NISN", value: "NISN" },
              { text: "L/P", value: "SEX" },
              { text: "Tanggal Lahir", value: "DOB" },
              { text: "Nomor Pendaftaran", value: "REGNUMBER" },
              { text: "Pilihan Sekolah", value: "PILIHAN" },
              { text: "Status", value: "VALIDATION" },
              { text: "Aksi", value: "actions", sortable: false },
              { text: "", value: "data-table-expand" },
            ];
          } else {
            this.headers = [
              { text: "No", value: "nomor" },
              {
                text: "Nama Lengkap",
                align: "left",
                value: "NAME",
              },
              { text: "NISN", value: "NISN" },
              { text: "L/P", value: "SEX" },
              { text: "Tanggal Lahir", value: "DOB" },
              { text: "Nomor Pendaftaran", value: "REGNUMBER" },
              { text: "Status", value: "VALIDATION" },
              { text: "Aksi", value: "actions", sortable: false },
              { text: "", value: "data-table-expand" },
            ];
          }
        })
        .catch((error) => {
          this.dialogLoading = false;
          console.log("error : " + error);
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    jalurChange: function (e) {
      // console.log(e);

      // this.dialogLoading = true;
      this.getRegistered(e);
    },

    detailSiswa(item) {
      // this.editedIndex = this.student.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialog = true;
      // console.log(item.NISN);
      // console.log(item.NAME);
      localStorage.useridDetail = item.id;
      localStorage.USERID = item.USERID;
      localStorage.nisnDetail = item.NISN;
      localStorage.nameDetail = item.NAME;
      localStorage.admissionDetail = item.ADMISSIONID;
      localStorage.regnumberDetail = item.REGNUMBER;
      localStorage.validationDetail = item.VALIDATION;
      this.$router.replace({ name: "Detail" });
    },

    editItem(item) {
      this.editedIndex = this.student.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.student.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      this.axios
        .post("/api/deleteDuplicateRegistered", formData, config)
        .then(() => {
          this.$vloading.hide();
          // let { data } = response.data;
          //this.setAuth(data);
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.closeDelete();
          this.$router.go();
        })
        .catch((error) => {
          this.$vloading.hide();
          let responses = error;
          // console.log(responses);
          // let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: responses.data.errors,
          });
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.student[this.editedIndex], this.editedItem);
      } else {
        this.student.push(this.editedItem);
      }
      this.close();
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>