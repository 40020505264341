import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Siswa from '../views/Siswa.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pendaftar',
    name: 'Pendaftar',
    component: () => import( /* webpackChunkName: "about" */ '../views/Pendaftar.vue')
  },
  {
    path: '/hasil-penerimaan',
    name: 'Hasil Penerimaan',
    component: () => import( /* webpackChunkName: "about" */ '../views/HasilPenerimaan.vue')
  },
  {
    path: '/bukti-verifikasi',
    name: 'Bukti Verifikasi',
    component: () => import( /* webpackChunkName: "about" */ '../views/BuktiVerifikasi.vue')
  },
  {
    path: '/bukti-pendaftaran',
    name: 'Bukti Pendaftaran',
    component: () => import( /* webpackChunkName: "about" */ '../views/BuktiPendaftaran.vue')
  },
  {
    path: '/bukti-pendaftaran-admin',
    name: 'Bukti Pendaftaran Admin',
    component: () => import( /* webpackChunkName: "about" */ '../views/BuktiPendaftaranAdmin.vue')
  },
  {
    path: '/bukti-diterima',
    name: 'Bukti Diterima',
    component: () => import( /* webpackChunkName: "about" */ '../views/BuktiDiterima.vue')
  },
  {
    path: '/cari-siswa',
    name: 'Cari Siswa',
    component: () => import( /* webpackChunkName: "about" */ '../views/CariSiswa.vue')
  },
  {
    path: '/biodata',
    name: 'Biodata',
    component: () => import( /* webpackChunkName: "about" */ '../views/Biodata.vue')
  },
  {
    path: '/biodata-orangtua',
    name: 'Biodata Orangtua',
    component: () => import( /* webpackChunkName: "about" */ '../views/BiodataOrangtua.vue')
  },
  {
    path: '/pilihan',
    name: 'Pilihan',
    component: () => import( /* webpackChunkName: "about" */ '../views/Pilihan.vue')
  },
  {
    path: '/persyaratan',
    name: 'Persyaratan',
    component: () => import( /* webpackChunkName: "about" */ '../views/Persyaratan.vue')
  },
  {
    path: '/masuk',
    name: 'Masuk',
    component: () => import( /* webpackChunkName: "about" */ '../views/Masuk.vue')
  },
  {
    path: '/daftar',
    name: 'Daftar',
    component: () => import( /* webpackChunkName: "about" */ '../views/Daftar.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import( /* webpackChunkName: "about" */ '../views/Categories.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( /* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/siswa',
    name: 'Siswa',
    component: Siswa
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import( /* webpackChunkName: "about" */ '../views/DetailSiswa.vue')
  },
  {
    path: '/jalur',
    name: 'Jalur',
    component: () => import( /* webpackChunkName: "about" */ '../views/JalurPenerimaan.vue')
  },
  {
    path: '/persyaratan-jalur',
    name: 'Persyaratan Jalur',
    component: () => import( /* webpackChunkName: "about" */ '../views/PersyaratanJalur.vue')
  },
  {
    path: '/persyaratan-jalur-dokumen',
    name: 'Persyaratan Jalur Dokumen',
    component: () => import( /* webpackChunkName: "about" */ '../views/PersyaratanJalurDokumen.vue')
  },
  {
    path: '/pilih-jalur',
    name: 'Pilih Jalur',
    component: () => import( /* webpackChunkName: "about" */ '../views/JalurPenerimaanSiswa.vue')
  },
  {
    path: '/nilai',
    name: 'Nilai',
    component: () => import( /* webpackChunkName: "about" */ '../views/Nilai.vue')
  },
  {
    path: '/sekolah-jalur',
    name: 'Sekolah Jalur',
    component: () => import( /* webpackChunkName: "about" */ '../views/SekolahJalur.vue')
  },
  {
    path: '/sekolah',
    name: 'Sekolah',
    component: () => import( /* webpackChunkName: "about" */ '../views/Sekolah.vue')
  },
  {
    path: '/mata-pelajaran',
    name: 'Mapel',
    component: () => import( /* webpackChunkName: "about" */ '../views/Mapel.vue')
  },
  {
    path: '/zonasi',
    name: 'Zonasi',
    component: () => import( /* webpackChunkName: "about" */ '../views/Zonasi.vue')
  },
  {
    path: '/bank-soal',
    name: 'Bank Soal',
    component: () => import( /* webpackChunkName: "about" */ '../views/BankSoal.vue')
  },
  {
    path: '/artikel',
    name: 'Artikel',
    component: () => import( /* webpackChunkName: "about" */ '../views/Artikel.vue')
  },
  {
    path: '/rekap',
    name: 'Rekap',
    component: () => import( /* webpackChunkName: "about" */ '../views/Rekap.vue')
  },
  {
    path: '/scoring',
    name: 'Scoring',
    component: () => import( /* webpackChunkName: "about" */ '../views/Scoring.vue')
  },
  {
    path: '/scoring-final',
    name: 'Scoring Final',
    component: () => import( /* webpackChunkName: "about" */ '../views/ScoringFinal.vue')
  },
  {
    path: '/scoring-beta',
    name: 'Scoring Beta',
    component: () => import( /* webpackChunkName: "about" */ '../views/ScoringBeta.vue')
  },
  {
    path: '/scoring-publish',
    name: 'Scoring Publish',
    component: () => import( /* webpackChunkName: "about" */ '../views/ScoringPublish.vue')
  },
  {
    path: '/mapping-data',
    name: 'Mapping Data',
    component: () => import( /* webpackChunkName: "about" */ '../views/MappingData.vue')
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: () => import( /* webpackChunkName: "about" */ '../views/Laporan.vue')
  },
  {
    path: '/tidak-diterima',
    name: 'Tidak Diterima',
    component: () => import( /* webpackChunkName: "about" */ '../views/TidakDiterima.vue')
  },
  {
    path: '/regis-ulang',
    name: 'Regis Ulang',
    component: () => import( /* webpackChunkName: "about" */ '../views/RegisUlang.vue')
  },
  {
    path: '/regis-ulang-admin',
    name: 'Regis Ulang Admin',
    component: () => import( /* webpackChunkName: "about" */ '../views/RegisUlangDiknas.vue')
  },
  {
    path: '/regis-check',
    name: 'Regis Check',
    component: () => import( /* webpackChunkName: "about" */ '../views/RegisCheck.vue')
  },
  {
    path: '/regis-cetak',
    name: 'Regis Ulang Cetak',
    component: () => import( /* webpackChunkName: "about" */ '../views/RegisUlangCetak.vue')
  },
  {
    path: '/soal',
    name: 'Soal',
    component: () => import( /* webpackChunkName: "about" */ '../views/Soal.vue')
  },
  {
    path: '/opsi-jawaban',
    name: 'Opsi Jawaban',
    component: () => import( /* webpackChunkName: "about" */ '../views/Jawaban.vue')
  },
  {
    path: '/cbt',
    name: 'Cbt',
    component: () => import( /* webpackChunkName: "about" */ '../views/Cbt.vue')
  },
  {
    path: '/cbt-nilai',
    name: 'Cbt Nilai',
    component: () => import( /* webpackChunkName: "about" */ '../views/CbtNilai.vue')
  },
  {
    path: '/cbt-siswa',
    name: 'Cbt Siswa',
    component: () => import( /* webpackChunkName: "about" */ '../views/CbtSiswa.vue')
  },
  {
    path: '/ujian-cbt-siswa',
    name: 'Ujian Cbt Siswa',
    component: () => import( /* webpackChunkName: "about" */ '../views/CbtSiswaUjian.vue')
  },
  {
    path: '/akun-diknas',
    name: 'Akun Diknas',
    component: () => import( /* webpackChunkName: "about" */ '../views/AkunDiknas.vue')
  },
  {
    path: '/akun-operator',
    name: 'Akun Operator',
    component: () => import( /* webpackChunkName: "about" */ '../views/AkunOperator.vue')
  },
  {
    path: '/akun-siswa',
    name: 'Akun Siswa',
    component: () => import( /* webpackChunkName: "about" */ '../views/AkunSiswa.vue')
  },
  {
    path: '/ubah-password',
    name: 'Ubah Password',
    component: () => import( /* webpackChunkName: "about" */ '../views/UbahPassword.vue')
  },
  {
    path: '/testing',
    name: 'Testing',
    component: () => import( /* webpackChunkName: "about" */ '../views/Testing.vue')
  },
  {
    path: '/category/:slug',
    name: 'category',
    component: () => import( /* webpackChunkName: "about" */ '../views/Category.vue')
  },
  {
    path: '/book/:slug',
    name: 'book',
    component: () => import( /* webpackChunkName: "about" */ '../views/Book.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router