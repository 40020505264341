<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title to="/">{{ appName }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar> -->

    <v-app-bar app color="primary" dark clipped-left>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-lg-and-up"
      ></v-app-bar-nav-icon>
      <v-img
        src="/img/icons/kota.png"
        max-width="40px"
        class="mb-1 mr-1"
      ></v-img>
      <v-toolbar-title to="/" class="hidden-xs-only font-weight-medium">
        &nbsp;&nbsp;{{ appName }}
      </v-toolbar-title>
      <v-toolbar-title to="/" class="hidden-sm-and-up font-weight-medium">
        &nbsp;&nbsp;{{ appName }}
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" clipped>
      <v-container style="width: 40%" v-if="!guest">
        <v-fade-transition mode="out-in">
          <v-row>
            <v-col cols="12">
              <v-img
                v-if="this.user.role == '3'"
                :src="baseUrl + url + user.username"
                style="border-radius: 50%"
              ></v-img>

              <v-img
                v-if="this.user.role == '1'"
                src="/img/icons/admin.png"
                style="border-radius: 50%"
              ></v-img>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container>
      <div class="text-center mb-2" style="color: black" v-if="!guest">
        <b v-if="user.full_name == null">{{ user.username }}</b>
        <b v-else>{{ user.full_name }}</b>
        <br /><small>{{ user.email }}</small>
      </div>

      <v-container style="width: 45%" v-if="guest">
        <v-fade-transition mode="out-in">
          <v-row>
            <v-col cols="12">
              <v-img src="/img/icons/kota.png"></v-img>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container>
      <div class="text-center mb-2" style="color: black" v-if="guest">
        <b>PPDB 2024</b><br /><small>Kota Gorontalo</small>
      </div>
      <v-divider></v-divider>

      <div v-if="guest">
        <v-list dense>
          <v-list-item
            v-for="(item, index) in menuGuest"
            :key="`menu-` + index"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <div v-if="!guest">
        <v-list v-if="studentBio == 'false' && this.user.role == '3'" dense>
          <span v-if="this.user.temp == null">
            <v-list-item
              v-for="(item, index) in menuUserFalseTemp"
              :key="`menu-` + index"
              :to="item.route"
            >
              <v-list-item-icon>
                <v-icon left>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </span>

          <span v-else>
            <v-list-item
              v-for="(item, index) in menuUserFalse"
              :key="`menu-` + index"
              :to="item.route"
            >
              <v-list-item-icon>
                <v-icon left>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </span>
        </v-list>

        <v-list v-if="studentBio == 'true' && this.user.role == '3'" dense>
          <v-list-item
            v-for="(item, index) in menuUserTrue"
            :key="`menu-` + index"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="this.user.role == '2'" dense>
          <v-list-item
            v-for="(item, index) in menuOperator"
            :key="`menu-` + index"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="this.user.role == '5'" dense>
          <v-list-item
            v-for="(item, index) in menuSoal"
            :key="`menu-` + index"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="this.user.role == '6'" dense>
          <v-list-item
            v-for="(item, index) in menuKecamatan"
            :key="`menu-` + index"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="this.user.role == '1'" dense>
          <v-list-item
            v-for="(item, index) in menuAdmin"
            :key="`menu-` + index"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-for="item in itemsLaporan"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-for="item in itemsScoring"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>

      <template v-slot:append v-if="!guest">
        <div class="pa-2">
          <v-btn
            block
            color="red"
            dark
            @click="logout"
            read-only
            :loading="loading"
          >
            <v-icon left>mdi-lock</v-icon>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <alert />

    <keep-alive>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialogbottom-transition"
      >
        <component :is="currentComponent" @closed="setDialogStatus"></component>
      </v-dialog>
    </keep-alive>

    <v-main class="bgcolor">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer>
      <v-card-text class="text-center">
        &copy; {{ new Date().getFullYear() }} —
        <strong>{{ appName }}</strong>
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Alert: () =>
      import(/* webpackChunkName: "alert" */ "@/components/Alert.vue"),
  },
  data: () => ({
    baseUrl: process.env.VUE_APP_API_URL,
    url: "/api/getPhoto/",
    drawer: true,
    loading: false,
    studentBio: null,
    menuUserFalseTemp: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Biodata", icon: "mdi-account", route: "/register" },
    ],
    menuUserFalse: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Biodata", icon: "mdi-account", route: "/biodata" },
    ],
    menuUserTrue: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Biodata", icon: "mdi-account", route: "/biodata" },
      {
        title: "Biodata Orang Tua",
        icon: "mdi-account-multiple",
        route: "/biodata-orangtua",
      },
      // { title: "Pilih Sekolah", icon: "mdi-school", route: "/pilihan" },
      // {
      //   title: "Upload Dokumen",
      //   icon: "mdi-file-document-multiple",
      //   route: "/persyaratan",
      // },
      {
        title: "Jalur Penerimaan",
        icon: "mdi-clipboard-text",
        route: "/pilih-jalur",
      },
      {
        title: "Nilai",
        icon: "mdi-seal",
        route: "/nilai",
      },
      {
        title: "Asesmen Diagnostik",
        icon: "mdi-file-tree",
        route: "/cbt-siswa",
      },
      {
        title: "Ubah Password",
        icon: "mdi-lock-reset",
        route: "/ubah-password",
      },
    ],
    menuAdmin: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Siswa", icon: "mdi-account", route: "/siswa" },
      // { title: "Registrasi Ulang", icon: "mdi-account-reactivate", route: "/regis-ulang-admin" },
      {
        title: "Jalur Penerimaan",
        icon: "mdi-clipboard-text",
        route: "/jalur",
      },
      {
        title: "CBT",
        icon: "mdi-file-tree",
        route: "/cbt",
      },
      {
        title: "Bank Soal",
        icon: "mdi-database-cog",
        route: "/bank-soal",
      },
      {
        title: "Artikel",
        icon: "mdi-newspaper-variant-outline",
        route: "/artikel",
      },
      // {
      //   title: "Rekap",
      //   icon: "mdi-file-chart",
      //   route: "/rekap",
      // },
      // {
      //   title: "Scoring Temp",
      //   icon: "mdi-seal",
      //   route: "/scoring",
      // },
      // {
      //   title: "Scoring Final",
      //   icon: "mdi-seal",
      //   route: "/scoring-final",
      // },
      // {
      //   title: "Mapping Data",
      //   icon: "mdi-table-of-contents",
      //   route: "/mapping-data",
      // },
    ],
    menuOperator: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Siswa", icon: "mdi-account", route: "/siswa" },
      {
        title: "Registrasi Ulang",
        icon: "mdi-account-reactivate",
        route: "/regis-ulang",
      },
      { title: "Cari Siswa", icon: "mdi-account-search", route: "/cari-siswa" },
      { title: "Sekolah", icon: "mdi-school", route: "/sekolah" },
      {
        title: "Ubah Password",
        icon: "mdi-lock-reset",
        route: "/ubah-password",
      },
    ],
    menuKecamatan: [
      { title: "Home", icon: "mdi-home", route: "/" },
      {
        title: "Akun Siswa",
        icon: "mdi-account-multiple",
        route: "/akun-siswa",
      },
      {
        title: "Akun Operator",
        icon: "mdi-account-multiple",
        route: "/akun-operator",
      },
      {
        title: "Ubah Password",
        icon: "mdi-lock-reset",
        route: "/ubah-password",
      },
    ],
    menuSoal: [
      { title: "Home", icon: "mdi-home", route: "/" },
      {
        title: "Bank Soal",
        icon: "mdi-database-cog",
        route: "/bank-soal",
      },
      {
        title: "Ubah Password",
        icon: "mdi-lock-reset",
        route: "/ubah-password",
      },
    ],
    menuGuest: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Pendaftar", icon: "mdi-table", route: "/pendaftar" },
      {
        title: "Hasil Penerimaan",
        icon: "mdi-format-list-bulleted",
        route: "/hasil-penerimaan",
      },
    ],
    items: [
      {
        action: "mdi-archive",
        items: [
          { title: "Sekolah", route: "/sekolah" },
          { title: "Mata Pelajaran", route: "/mata-pelajaran" },
          { title: "Akun Diknas", route: "/akun-diknas" },
          { title: "Akun Operator", route: "/akun-operator" },
          { title: "Akun Siswa", route: "/akun-siswa" },
          { title: "Cari Siswa", route: "/cari-siswa" },
          { title: "Ubah Password", route: "/ubah-password" },
        ],
        title: "Data Master",
      },
    ],
    itemsScoring: [
      {
        action: "mdi-seal",
        items: [
          // { title: "Scoring Temp", route: "/scoring" },
          // { title: "Scoring Final", route: "/scoring-final" },
          { title: "Scoring", route: "/scoring-beta" },
          { title: "Scoring Publish", route: "/scoring-publish" },
        ],
        title: "Scoring",
      },
    ],
    itemsLaporan: [
      {
        action: "mdi-file-chart",
        items: [
          { title: "Laporan PPDB 2024", route: "/laporan" },
          { title: "Diterima", route: "/mapping-data" },
          { title: "Tidak Diterima", route: "/tidak-diterima" },
          { title: "Registrasi Ulang", route: "/regis-ulang-admin" },
          { title: "Rekap", route: "/rekap" },
        ],
        title: "Laporan",
      },
    ],
  }),
  updated() {
    this.getStudentBio();
  },
  methods: {
    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAuth: "auth/set", // <=
      setAlert: "alert/set", // <=
    }),
    getStudentBio() {
      this.studentBio = localStorage.getItem("studentBio");
    },
    /* tambahkan fungsi logout */
    logout() {
      this.loading = true;
      localStorage.removeItem("studentBio");
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .post("/api/auth/logout", {}, config)
        .then(() => {
          this.setAuth({}); // kosongkan auth ketika logout
          // localStorage.removeItem("token");
          this.setAlert({
            status: true,
            color: "success",
            text: "Logout successfully",
          });
          this.$router.replace({ name: "Home" });
          // this.$router.go(0);
          // window.location.reload();
          localStorage.clear();
          this.loading = false;
        })
        .catch((error) => {
          let { data } = error.response;
          console.log(data.code);
          if (data.code == undefined) {
            this.setAuth({}); // kosongkan auth ketika logout
            this.setAlert({
              status: true,
              color: "success",
              text: "Logout successfully",
            });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: data.message,
            });
          }
          this.loading = false;
        });
    },
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
};
</script>
