import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/helper';
import VueConfetti from 'vue-confetti'
import VueApexCharts from 'vue-apexcharts'
import JsonExcel from "vue-json-excel";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import {
  LoadingPlugin
} from "vuetify-loading-overlay"
import JsonCSV from 'vue-json-csv'

Vue.component('downloadCsv', JsonCSV)

Vue.use(LoadingPlugin, {
  //props
  spinnerProps: {},
  overlayProps: {},
});

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.use(VueApexCharts)
const moment = require('moment')
require('moment/locale/id')

Vue.use(require('vue-moment'), {
  moment
})

Vue.component('apexchart', VueApexCharts)
Vue.component("downloadExcel", JsonExcel)

Vue.use(VueConfetti)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')