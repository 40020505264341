import Vue from 'vue';
import Vuex from 'vuex';
import cart from '@/store/cart';
import alert from '@/store/alert';
import auth from '@/store/auth';
import dialog from '@/store/dialog';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cart,
    alert,
    auth,
    dialog,
  },
  plugins: [createPersistedState()],
})

export default store;