<template>
  <div>
    <v-carousel height="100%" hide-delimiters :show-arrows="false">
      <v-carousel-item
        v-for="(item, i) in gambar"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <v-divider></v-divider>
    <v-alert color="blue" dark border="top" prominent class="text-center">
      <b>Selamat Datang di Website PPDB SD dan SMP Kota Gorontalo Tahun 2024</b>
      <!-- <div class="pa-2" v-if="guest">
        <v-btn block color="primary" @click="setDialogComponent('login')">
          <v-icon left>mdi-lock</v-icon>
          Masuk
        </v-btn>
        <v-btn block color="success" @click="setDialogComponent('register')">
          <v-icon left>mdi-account</v-icon>
          Daftar
        </v-btn>
      </div> -->

      <v-container v-if="guest">
        <v-row>
          <v-col cols="12" sm="4" md="4" offset-md="1" offset-sm="1">
            <v-btn block color="warning" @click="login()">
              <v-icon left>mdi-lock</v-icon>
              Masuk
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-btn block color="success" @click="register()">
              <v-icon left>mdi-account</v-icon>
              Daftar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-btn block color="#FF5722" @click="registerTK()">
              <v-icon left>mdi-account</v-icon>
              Daftar TK
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-alert>

    <v-alert
      color="warning"
      dark
      prominent
      v-if="studentBio == 'false' && this.user.role == '3'"
    >
      <b>Perhatian!</b> Silahkan melengkapi biodata terlebih dahulu di menu
      <b>Biodata</b>.
    </v-alert>

    <span v-if="user.role == '3'">
      <v-alert
        color="green"
        dark
        prominent
        v-if="user.accepted != null"
        class="text-center"
      >
        <b
          >Selamat, Anda dinyatakan diterima di
          {{ user.accepted.school.NAME }}.</b
        >
        <br />
        Silahkan mencetak bukti diterima melalui tombol dibawah ini<br />
        <v-btn small class="mr-1" color="orange" dark @click="diterima()">
          <v-icon left> mdi-printer </v-icon>
          Bukti Diterima
        </v-btn>
      </v-alert>
    </span>

    <v-data-table
      :headers="headers"
      :items="admission"
      class="elevation-1 mb-5"
      item-key="name"
      v-if="studentBio == 'true' && this.user.role == '3'"
    >
      <template v-slot:item.nomor="{ item }">
        {{ admission.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.STATUS="{ item }">
        <span v-if="item.STATUS == 1">Dibuka</span>
        <span v-else>Ditutup</span>
      </template>
      <template v-slot:item.NOMOR="{ item }">
        <span v-if="item.admission_choice != null">
          {{ item.admission_choice.REGNUMBER }}
        </span>
      </template>
      <template v-slot:item.hasil="{ item }">
        <span v-if="item.PUBLISHED == 0">
          <v-chip color="orange" dark small>Proses Seleksi</v-chip>
        </span>
        <span v-else>
          <span v-if="item.admission_choice != null">
            <span v-if="item.admission_choice.STATUS == 1">
              <v-chip color="green" dark small>Diterima</v-chip>
            </span>
            <span v-else>
              <v-chip color="red" dark small>Tidak Diterima</v-chip>
            </span>
          </span>
        </span>
      </template>
      <template v-slot:item.bukti="{ item }">
        <span v-if="item.admission_choice != null">
          <v-btn
            small
            class="mr-1"
            color="info"
            dark
            @click="pendaftaran(item)"
          >
            <v-icon left> mdi-printer </v-icon>
            Bukti Pendaftaran
          </v-btn>
          <!-- <span v-if="now > new Date(item.DDAY).getTime()"> -->
          <!-- {{ now }}<br>
            {{ new Date(item.DDAY).getTime() }} -->

          <!-- </span> -->
        </span>
        <!-- <v-btn
          small
          class="mr-1"
          color="success"
          dark
          @click="sekolahList(item)"
        >
          <v-icon left> mdi-download </v-icon>
          Bukti Diterima
        </v-btn> -->
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Jalur Penerimaan Yang Dipilih</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
    <!-- <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Cek Data Siswa </v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="nisn"
            :rules="nisnRules"
            type="number"
            label="NISN (Nomor Induk Siswa Nasional)"
            required
          ></v-text-field>
          <v-text-field
            v-model="npsn"
            :rules="npsnRules"
            type="number"
            label="NPSN (Nomor Pokok Sekolah Nasional)"
            required
          ></v-text-field>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            v-model="dob"
            :rules="dobRules"
            type="date"
            label="Tanggal Lahir"
            required
          ></v-text-field>
          <div class="text-xs-center">
            <v-btn color="primary" :disabled="!valid" @click="submit">
              Cek Data
              <v-icon right dark>mdi-lock-open</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-card> -->

    <!-- <button @click="start">Start</button>
    <button @click="stop">Stop</button>
    <button @click="love">Show some love</button> -->

    <v-row>
      <v-col cols="12" sm="12" md="7">
        <!-- Artikel -->
        <v-card>
          <v-toolbar dark color="primary" tile>
            <v-toolbar-title>Artikel</v-toolbar-title>
          </v-toolbar>
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                class="pt-3"
                v-for="art in artikel"
                :key="art.id"
              >
                <h3>{{ art.title }}</h3>
                <p style="color: gray">
                  <small
                    ><v-icon small class="pr-1 mb-1">mdi-calendar</v-icon
                    >{{ art.created_at | moment("DD MMMM YYYY") }}</small
                  >
                </p>
                <p v-html="art.content">{{ art.content }}</p>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <!-- Jalur Penerimaan Publik -->
        <v-data-table
          :headers="headersPublic"
          :items="admissionPublic"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:item.nomor="{ item }">
            {{ admissionPublic.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.Jalur="{ item }">
            <b>{{ item.NAME }}</b
            ><br />
            <small>
              Buka : {{ item.OPEN | moment("DD MMMM YYYY") }}, Pukul
              {{ item.OPEN | moment("HH:mm") }} WITA <br />
              Tutup : {{ item.CLOSE | moment("DD MMMM YYYY") }}, Pukul
              {{ item.CLOSE | moment("HH:mm") }} WITA
            </small>
          </template>
          <template v-slot:item.OPEN="{ item }">
            {{ item.OPEN | moment("DD MMMM YYYY") }},<br />Pukul
            {{ item.OPEN | moment("HH:mm") }} WITA
          </template>
          <template v-slot:item.CLOSE="{ item }">
            {{ item.CLOSE | moment("DD MMMM YYYY") }},<br />Pukul
            {{ item.CLOSE | moment("HH:mm") }} WITA
          </template>
          <template v-slot:item.STATUS="{ item }">
            <span v-if="item.STATUS == 1">
              <v-chip color="green" dark small>Buka</v-chip></span
            >
            <span v-else><v-chip color="red" dark small>Tutup</v-chip></span>
          </template>
          <template v-slot:top>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Jalur Penerimaan</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:no-data> Tidak ada data </template>
        </v-data-table>
        <br />
        <v-card elevation="1" class="pa-5">
          <h3>Statistik Pendaftar {{ appName }}</h3>
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogConf"
      persistent
      max-width="750px"
      transition="dialog-top-transition"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <v-card>
        <v-toolbar color="primary" dark>Selamat Datang!</v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="text-center">
              <div class="text-h4 pa-10">
                <b
                  >Pembukaan PPDB online SD dan SMP TP. 2024-2025 Kota
                  Gorontalo</b
                >
              </div>
              <div class="text-h5"><b>Oleh</b></div>
              <div class="text-h4"><b>Walikota Gorontalo</b></div>
              <!-- <h2 class="pa-12">Pembukaan PPDB online SD dan SMP TP. 2024-2025 Kota Gorontalo oleh Walikota Gorontalo</h2> -->
            </v-col>
            <v-col cols="12" sm="12" md="12" class="text-center">
              <v-btn color="primary" dark @click="start()"> Buka </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Save
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <br />
    <!-- <v-card elevation="1" class="pa-5">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <h3>Statistik Pendaftar {{ appName }}</h3>
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-col>
      </v-row>
    </v-card> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      chartOptions: {},
      series: [],
      gambar: [
        { src: "/img/bg/ppdb_2024.jpg" },
        { src: "/img/bg/ppdb_2024.jpg" },
      ],
      valid: true,
      dialogConf: false,
      isSending: false,
      studentBio: localStorage.getItem("studentBio"),
      nisn: "0057534901",
      nisnRules: [(v) => !!v || "NISN wajib di isi"],
      npsn: "40501073",
      npsnRules: [(v) => !!v || "NPSN wajib di isi"],
      email: "admins@mail.com",
      emailRules: [
        (v) => !!v || "E-mail wajib di isi",
        (v) =>
          /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(
            v
          ) || "E-mail must be valid",
      ],
      dob: "2008-07-28",
      dobRules: [(v) => !!v || "Tanggal lahir wajib di isi"],
      nama: localStorage.getItem("nama"),

      // Jalur Penerimaan
      headers: [
        { text: "No", value: "nomor" },
        {
          text: "Nama",
          align: "left",
          value: "NAME",
        },
        { text: "Status", value: "STATUS" },
        { text: "Nomor Pendaftaran", value: "NOMOR" },
        // { text: "Aksi", value: "aksi" },
        { text: "Aksi", value: "bukti" },
        { text: "Hasil", value: "hasil" },
      ],
      admission: [],

      // Jalur Penerimaan
      headersPublic: [
        { text: "No", value: "nomor" },
        {
          text: "Jalur",
          align: "left",
          value: "Jalur",
        },
        // { text: "Buka", value: "OPEN" },
        // { text: "Tutup", value: "CLOSE" },
        { text: "Status", value: "STATUS" },
      ],
      admissionPublic: [],
      artikel: [],
      now: "",
    };
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getStatistic();
      this.getAdmission();
      this.getArticle();
    },

    getArticle() {
      let config = {};
      this.axios.post("/api/articleList", {}, config).then((response) => {
        var data = response.data.data;
        var art = data.reverse();
        this.artikel = art;
        //console.log(this.info);
      });
    },

    getStatistic() {
      let config = {
        headers: {},
      };
      this.axios
        .post("/api/getStatistic", {}, config)
        .then((response) => {
          console.log(response.data);
          var data = response.data;
          this.admissionPublic = data;
          const NAME = data.map((key) => {
            return key.NAME;
          });
          // console.log(NAME);

          const COUNT = data.map((key) => {
            return key.admission_choice_count;
          });
          // console.log(COUNT);

          this.chartOptions = {
            chart: {
              height: 350,
              type: "bar",
            },
            plotOptions: {
              bar: {
                columnWidth: "45%",
                distributed: true,
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: NAME,
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          };
          this.series = [
            {
              data: COUNT,
            },
          ];
        })
        .catch((error) => {
          console.log("error" + error);
        });
    },

    getAdmission() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios.post("/api/auth/admission", {}, config).then((response) => {
        // this.admission = response.data.data;
        // console.log(this.admission);

        var Data = response.data.data;
        var newData = Data.filter((e) => e.admission_choice != null);
        this.admission = newData;
        console.log("jalur dipilih ", newData);

        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;
        var tgl = new Date(dateTime).getTime();
        console.log("tgl", tgl);
        this.now = tgl;
      });
    },

    pendaftaran(item) {
      localStorage.admissionID = item.id;
      localStorage.admissionNAME = item.NAME;
      this.$router.replace({ name: "Bukti Pendaftaran" });
    },

    diterima() {
      localStorage.admissionID = this.user.accepted.ADMISSIONID;
      localStorage.admissionNAME = this.user.accepted.admission_data.NAME;
      localStorage.admissionRSTART = this.user.accepted.admission_data.RSTART;
      localStorage.admissionREND = this.user.accepted.admission_data.REND;
      this.$router.replace({ name: "Bukti Diterima" });
    },

    start() {
      this.dialogConf = false;
      this.$confetti.start();
      setTimeout(() => this.$confetti.stop(), 10000);
    },

    stop() {
      this.$confetti.stop();
    },

    love() {
      this.$confetti.update({
        particles: [
          {
            type: "heart",
          },
          {
            type: "circle",
          },
        ],
        defaultColors: ["red", "pink", "#ba0000"],
      });
    },

    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAuth: "auth/set", // <=
      setAlert: "alert/set", // <=
    }),
    login() {
      this.$router.replace({ name: "Masuk" });
    },
    register() {
      this.$router.replace({ name: "Daftar" });
    },
    submit() {
      if (this.$refs.form.validate()) {
        let formData = {
          nisn: this.nisn,
          npsn: this.npsn,
          email: this.email,
          dob: this.dob,
        };
        this.isSending = true;
        this.axios
          .post("/api/check", formData)
          .then((response) => {
            this.isSending = false;
            //let { data } = response.data[0];
            // console.log(response.data[0].peserta_didik_id);
            //console.log(data);
            // this.book = data;
            // this.addCart(this.book);
            localStorage.peserta_didik_id = response.data[0].peserta_didik_id;
            localStorage.sekolah_id = response.data[0].sekolah_id;
            localStorage.kode_wilayah = response.data[0].kode_wilayah;
            localStorage.nama = response.data[0].nama;
            localStorage.tempat_lahir = response.data[0].tempat_lahir;
            localStorage.tanggal_lahir = response.data[0].tanggal_lahir;
            localStorage.jenis_kelamin = response.data[0].jenis_kelamin;
            localStorage.nik = response.data[0].nik;
            localStorage.nisn = response.data[0].nisn;
            localStorage.alamat_jalan = response.data[0].alamat_jalan;
            localStorage.desa_kelurahan = response.data[0].desa_kelurahan;
            localStorage.rt = response.data[0].rt;
            localStorage.rw = response.data[0].rw;
            localStorage.nama_dusun = response.data[0].nama_dusun;
            localStorage.nama_ibu_kandung = response.data[0].nama_ibu_kandung;
            localStorage.pekerjaan_ibu_kandung =
              response.data[0].pekerjaan_ibu_kandung;
            localStorage.penghasilan_ibu_kandung =
              response.data[0].penghasilan_ibu_kandung;
            localStorage.nama_ayah = response.data[0].nama_ayah;
            localStorage.pekerjaan_ayah = response.data[0].pekerjaan_ayah;
            localStorage.penghasilan_ayah = response.data[0].penghasilan_ayah;
            localStorage.nama_wali = response.data[0].nama_wali;
            localStorage.pekerjaan_wali = response.data[0].pekerjaan_wali;
            localStorage.penghasilan_wali = response.data[0].penghasilan_wali;
            localStorage.kebutuhan_khusus = response.data[0].kebutuhan_khusus;
            localStorage.no_kip = response.data[0].no_kip;
            localStorage.no_pkh = response.data[0].no_pkh;
            localStorage.lintang = response.data[0].lintang;
            localStorage.bujur = response.data[0].bujur;
            localStorage.aktif = response.data[0].aktif;
            localStorage.tingkat_pendidikan =
              response.data[0].tingkat_pendidikan;
            localStorage.npsn = this.npsn;
            localStorage.email = this.email;
            // localStorage.removeItem('peserta_didik_id');
            this.setAlert({
              status: true,
              color: "success",
              text: "Cek data berhasil",
            });
            this.close();
            this.$router.replace({ name: "register" });
          })
          .catch((error) => {
            this.isSending = false;
            let { data } = error.response;
            //console.log(data);
            var pesan = "";
            if (data.message === "The given data was invalid.") {
              pesan = "Data Siswa Sudah Terdaftar, Silahkan Melakukan Login.";
            } else {
              pesan =
                "Data Siswa Tidak ditemukan / tidak tercatat pada sistem dapodik sebagai siswa tingkat akhir";
            }
            //console.log(data.errors.notfound);
            this.setAlert({
              status: true,
              color: "error",
              text: pesan,
            });
          });
      }
    },
    close() {
      this.$emit("closed", false);
    },

    registerTK() {
      // redirect to ppdb.gorontalokota.go.id/ppdb-tk
      window.open("https://ppdb.gorontalokota.go.id/ppdb-tk", "_blank");
    },
  },
};
</script>
